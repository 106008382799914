'use client';
import cn from 'classnames';
import { usePathname } from 'next/navigation';
import withTheme from '@theme/index';
import { ConfigProvider, Menu, MenuProps, ThemeConfig, Image, Flex, Affix, Dropdown } from 'antd';
import style from './index.module.scss';
import { Header } from 'antd/es/layout/layout';
import {
    GlobalSettingsFragment,
    InMenuItemFragment,
    MenuItemFragment,
} from '@/@types/generated/sitecore';
import HomeIcon from '@public/images/common/home_icon.svg';
import { useRouter } from 'next/navigation';
import { Locale } from '@/i18n/i18n.config';
import { withFullPath } from '@/utils/utils';
import { useEffect, useState } from 'react';
import Text from '@components/Text';
import { MenuOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import CustButton, { GeneralButtonType } from '../Button';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import { getButtonLinkWithCID, WATrackerClickEvn } from '@/utils/wa';
import { useTranslation } from '@/i18n/use-transition.client';
import LanguageIcon from '@components/SVG/LanguageIcon';
import useWindowSize from '@/utils/useScreenSize';
import ImagePreview from '../ImagePreview';

type IProps = {
    headerList: MenuItemFragment[];
    menuList: InMenuItemFragment[];
    theme?: ThemeConfig;
    lang?: Locale;
    globalSettingData: GlobalSettingsFragment;
};

type MenuListProps = {
    className?: string;
    setting?: MenuProps;
};

type MenuItem = Required<MenuProps>['items'][number];

const Navigator = (props: IProps) => {
    const { headerList, menuList, lang = 'en-US', globalSettingData } = props;
    const router = useRouter();
    const pathname = usePathname();
    const windowSize = useWindowSize();
    const globalStore = useGlobalStore();
    const { t } = useTranslation();
    const desktopBreakPoint = 1280;
    const isMobile = windowSize.width < desktopBreakPoint;

    const themeColor = globalStore.currentTheme?.themeColor;

    const [selectedKeys, setSelectedKeys] = useState('home');
    const [openNav, setOpenNav] = useState(false);
    const [pageState, setPageState] = useState({
        init: true,
    });

    const onOpenNav = () => {
        setOpenNav(true);
        setPageState({ ...pageState, init: false });
    };

    const onCloseNav = () => {
        setOpenNav(false);
    };

    const defaultTheme = {
        token: {
            colorPrimary: themeColor || '',
        },
        components: {
            Menu: {
                activeBarBorderWidth: 10,
                activeBarHeight: 2,
                itemColor: '#FFFFFF',
                itemBg: 'none',
                itemHeight: 10,
                itemHoverColor: 'inherit',
            },
        },
    };
    const theme = props.theme || defaultTheme;
    let navItems = menuList
        .filter((i) => i.enabled?.boolValue && i.headerMenu?.boolValue)
        .map((item) => {
            if (item.name === 'home') {
                return getItem(
                    '',
                    item.name,
                    item.destination?.value,
                    <div className='w-full h-full flex justify-center items-center'>
                        <HomeIcon />
                    </div>,
                );
            }
            return getItem(item.displayText?.value, item.name, item.destination?.value);
        });

    let navMobileItems: ItemType[] = [];
    menuList
        .filter((i) => i.enabled?.boolValue && i.headerMenu?.boolValue)
        .forEach((item) => {
            navMobileItems.push(
                getItem(
                    '',
                    item.name,
                    item.destination?.value,
                    <div
                        className=''
                        style={{ fontSize: '14px', fontWeight: 800 }}
                        onClick={(i) => {
                            onCloseNav();
                            setSelectedKeys(item.name);
                            setTimeout(() => {
                                setPageState({ ...pageState, init: true });
                                router.push(`/${lang}${item.destination?.value}`);
                            }, 200);
                        }}
                    >
                        {item.displayText?.value}
                    </div>,
                ),
            );
            navMobileItems.push({ type: 'divider' });
        });

    const navGlobalItems = headerList.map((item) => {
        return getItem(
            '',
            item.name,
            '',
            <CustButton
                data={{
                    className: 'text-base-font-color',
                    buttonLink: getButtonLinkWithCID(
                        item.destination?.value,
                        `SPLDSP_RnE/HW/${globalStore.pageID || ''}_Header-Menu-RnEdropdown`,
                        true,
                    ),
                    setting: {
                        type: 'link',
                        onClick: () => {
                            onCloseNav();
                            setSelectedKeys(item.name);
                            WATrackerClickEvn('RnE/HW_Click_Header-Menu-RnEdropdown');
                        },
                    },
                }}
            >
                <div className='text-base-font-color' style={{ fontSize: '14px', fontWeight: 800 }}>
                    {item.displayText?.value}
                </div>
            </CustButton>,
        );
    });

    // navMobileItems.push(
    //     getItem(
    //         '',
    //         'racecourses-entertainment',
    //         '',
    //         <div className='' style={{ fontSize: '14px', fontWeight: 800 }}>
    //             {t('racecourses')}
    //         </div>,
    //         navGlobalItems,
    //     ),
    // );
    // navMobileItems.push({ type: 'divider' });

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        link: React.ReactNode,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            link,
            children,
            label,
            type,
        } as MenuItem;
    }

    const languageFun: MenuProps['onClick'] = (info) => {
        globalStore.setGlobalStore({ lang: info.key as 'en-US' | 'zh-HK' });
        router.push(pathname.replace(lang, info.key));
    };

    // const searchClk = () => {
    //     lang === 'en-US'
    //         ? window.open('https://search.hkjc.com/english/index.html')
    //         : window.open('https://search.hkjc.com/chinese/index.html');
    // };

    const languageDropdown = (
        <ConfigProvider
            theme={{
                components: {
                    Dropdown: {
                        paddingBlock: 12,
                    },
                },
                token: {
                    colorText: '#FFFFFF',
                    colorBgElevated: '#061E35',
                },
            }}
        >
            <Dropdown
                menu={{
                    items: [
                        {
                            key: 'en-US',
                            label: (
                                <span
                                    style={{
                                        color:
                                            globalStore.lang === 'en-US' ? themeColor : '#FFFFFF',
                                    }}
                                >
                                    English
                                </span>
                            ),
                        },
                        {
                            key: 'zh-HK',
                            label: (
                                <span
                                    style={{
                                        color:
                                            globalStore.lang === 'zh-HK' ? themeColor : '#FFFFFF',
                                    }}
                                >
                                    中文繁體
                                </span>
                            ),
                        },
                        {
                            key: 'zh-CN',
                            label: (
                                <span
                                    style={{
                                        color:
                                            globalStore.lang === 'zh-CN' ? themeColor : '#FFFFFF',
                                    }}
                                >
                                    中文简体
                                </span>
                            ),
                        },
                    ],
                    selectable: true,
                    onClick: languageFun,
                }}
                trigger={['click']}
                placement='bottomLeft'
                arrow={false}
                overlayStyle={{ border: '1px solid #36536F', width: '105px', borderRadius: '5px' }}
            >
                <a
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <LanguageIcon />
                </a>
            </Dropdown>
        </ConfigProvider>
    );

    const MobileNav = () => {
        return (
            <div
                className={`fixed w-full h-full top-0 left-0 bg-primary-bg-color ${openNav ? 'translate-y-0 transition ease-in-out duration-300 delay-100' : '-translate-y-full transition ease-in-out duration-300 delay-100'}`}
            >
                <div
                    className={
                        'relative h-full w-full overflow-y-scroll overscroll-y-none pb-[120px]'
                    }
                >
                    <Flex
                        align='center'
                        justify='space-between'
                        className={
                            'relative top-0 basis-full z-10 p-5 bg-primary-bg-color border-b border-content-divide-color'
                        }
                    >
                        <div
                            className='flex h-[28px]'
                            onClick={() => {
                                WATrackerClickEvn('RnE/HW_Click_Header-Menu-eWin');
                                window.open(
                                    `https://bet.hkjc.com/index.aspx?lang=${lang === 'en-US' ? 'en' : 'ch'}&b_cid=SPLDSP_RnE/HW_Click_Header-Menu-eWin`,
                                );
                            }}
                        >
                            <ImagePreview
                                setting={{
                                    rootClassName: 'align-top',
                                    src: globalSettingData.ewinLogo?.src,
                                    width: 64,
                                    height: 28,
                                    alt: '',
                                }}
                            />
                        </div>
                        <div className={'absolute right-16'}>{languageDropdown}</div>
                        <div className={'flex'}>
                            <CloseOutlined
                                className={'text-[26px]'}
                                style={{ color: 'white' }}
                                onClick={onCloseNav}
                            />
                        </div>
                    </Flex>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorSplit: '#36536F',
                            },
                            components: {
                                Menu: {
                                    itemSelectedBg: 'transparent',
                                    itemActiveBg: 'transparent',
                                    itemHeight: 66,
                                    itemMarginInline: 0,
                                    itemPaddingInline: 20,
                                    iconMarginInlineEnd: 0,
                                    itemBorderRadius: 0,
                                },
                            },
                        }}
                    >
                        <MenuList
                            setting={{
                                mode: 'inline',
                                items: navMobileItems,
                                style: { borderInlineEnd: 0 },
                            }}
                        />
                    </ConfigProvider>
                    <div className='ml-5 mt-6'>
                        <CustButton
                            data={{
                                buttonLink: getButtonLinkWithCID(
                                    globalSettingData.headerCTAButtonLink?.value,
                                    `SPLDSP_RnE/HW_Click_Header-Menu-BookingRC`,
                                    true,
                                ),
                                setting: {
                                    onClick: () => {
                                        WATrackerClickEvn('RnE/HW_Click_Header-Menu-BookingRC');
                                    },
                                },
                            }}
                        >
                            <GeneralButtonType
                                // label={'Book Now'}
                                label={globalSettingData.headerCTAButtonText?.value}
                                icon={'arrowRightBK'}
                                // iconColor={themeColor}
                            />
                        </CustButton>
                    </div>
                </div>
            </div>
        );
    };

    const MenuList = ({ setting, className }: MenuListProps) => {
        const defaultSettings: MenuProps = {
            mode: 'horizontal',
            defaultSelectedKeys: ['home'],
            items: navItems,
            selectedKeys: [selectedKeys],
            className: cn(className, 'flex-1 min-w-0 justify-start menuStyle'),
            onClick: !isMobile
                ? (i) => {
                      onCloseNav();
                      setSelectedKeys(i.key);
                      setTimeout(() => {
                          setPageState({ ...pageState, init: true });
                          const url: any = navItems.filter((item: any) => item.key === i.key)[0];
                          router.push(`/${lang}${url.link}`);
                      }, 200);
                  }
                : undefined,
            ...setting,
        };
        return <Menu {...defaultSettings} />;
    };

    useEffect(() => {
        const urlKeys = pathname.match(`/${lang}(.+)/`) || '/';
        const getSelectedKeys = urlKeys[1] || '/';
        const partyKeys = getSelectedKeys.match(/\/parties/) || '/';
        const albumKeys = getSelectedKeys.match(/\/album/) || '/';
        const partyLen = getSelectedKeys.split('/').filter(Boolean);

        navItems.forEach((i: any) => {
            if (i.link === getSelectedKeys) {
                setSelectedKeys(i.key);
            } else if (partyKeys[0] && partyLen.length > 1) {
                setSelectedKeys('currently-on');
            } else if (i.link === albumKeys[0]) {
                setSelectedKeys(i.key);
            }
        });
    }, [lang, navItems, pathname]);

    return withTheme(
        <div className={cn('absolute top-0 max-w-[1920px] w-full h-full', style.navigatorWrap)}>
            <Affix offsetTop={0}>
                <Header
                    className={cn(
                        'flex sticky justify-between items-center w-full top-0 z-[2] !px-4 !py-3 xmd:!px-20 xmd:!py-5 !h-fit',
                    )}
                >
                    <div className={'flex items-center flex-1'}>
                        <a
                            href={'/'}
                            className={cn('h-[60px]', windowSize.width < 1350 ? 'mr-8' : 'mr-16')}
                        >
                            <Image
                                preview={false}
                                // src={withBasePath('/images/common/hw_logo_w.png')}
                                src={withFullPath(globalSettingData?.hwHeaderLogo?.src)}
                                width={118}
                                height={60}
                                alt=''
                            />
                        </a>
                        {!isMobile && <MenuList />}
                    </div>
                    {!isMobile ? (
                        <div className={'flex flex-row gap-10 items-center'}>
                            {languageDropdown}
                            <CustButton
                                data={{
                                    //TODO: book now link
                                    buttonLink: getButtonLinkWithCID(
                                        globalSettingData.headerCTAButtonLink?.value,
                                        `SPLDSP_RnE/HW_Click_Header-Menu-BookingRC`,
                                        true,
                                    ),
                                    setting: {
                                        onClick: () => {
                                            WATrackerClickEvn('RnE/HW_Click_Header-Menu-BookingRC');
                                        },
                                    },
                                    btnSpacing: '!py-3 !px-8',
                                }}
                            >
                                <Text
                                    className={cn(
                                        'font-extrabold leading-[19px] xmd:leading-[22px] text-black',
                                    )}
                                    level={'body2'}
                                    text={globalSettingData.headerCTAButtonText?.value}
                                />
                            </CustButton>
                            <div
                                className='cursor-pointer h-[28px]'
                                onClick={() => {
                                    WATrackerClickEvn('RnE/HW_Click_Header-Menu-eWin');
                                    window.open(
                                        `https://bet.hkjc.com/index.aspx?lang=${lang === 'en-US' ? 'en' : 'ch'}&b_cid=SPLDSP_RnE/HW_Click_Header-Menu-eWin`,
                                    );
                                }}
                            >
                                <ImagePreview
                                    setting={{
                                        rootClassName: 'align-top',
                                        src: globalSettingData.ewinLogo?.src,
                                        width: 64,
                                        height: 28,
                                        alt: '',
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <MenuOutlined
                                className={'flex-1 min-w-0 justify-end text-[26px]'}
                                style={{ color: 'white' }}
                                onClick={onOpenNav}
                            />
                            <MobileNav />
                        </>
                    )}
                </Header>
            </Affix>
        </div>,
        theme,
    );
};

export default Navigator;
