'use client';

import { useGlobalStore } from '@/hooks/useGlobalStore';
import { withFullPath } from '@/utils/utils';
import { Image } from 'antd';

type iconProp = {
    isCustomIcon?: boolean;
    className?: string;
    icon: string;
    size?: string;
    color?: string;
    onClick?: React.MouseEventHandler;
};

export const Icon = ({ isCustomIcon = false, className, icon, size, color, onClick }: iconProp) => {
    const globalStore = useGlobalStore();

    const themeColor =
        globalStore.currentTheme?.themeColor;

    return (
        <Image
            src={withFullPath(icon)}
            className='rounded-full'
            style={{ backgroundColor: themeColor }}
            preview={false}
            width={40}
            height={40}
            alt=''
        />
    );
};
