'use client';
import { Flex } from 'antd';
import cn from 'classnames';
import Heading from '@componentsHeading';
import TitleIconRight from '@public/images/common/wave_icon_r.svg';
import style from './index.module.scss';
import CustButton, { GeneralButtonType } from '@componentsButton';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import RichText from '../RichText';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import Dialog from './index';
import LeaveSiteType from './LeaveSiteType';
import { useState } from 'react';
import { useRouter } from 'next/navigation';

type attentionProps = {
    data: {
        title: string;
        content: string;
        btn: {
            label: string;
            url?: string;
        };
    };
    className?: string;
    onButtonClick?: () => void;
    currentMusicWaveColor?: string;
};

const AttentionType = ({
    data,
    className,
    onButtonClick,
    currentMusicWaveColor,
}: attentionProps) => {
    const globalStore = useGlobalStore();
    const router = useRouter();
    const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
    // const globalData = globalStore.globalSettingData;
    const dynamicStyle: any = {
        '--scrollbar-thumb-color': globalStore?.currentTheme?.themeColor,
    };

    const musicWaveColor = globalStore?.currentTheme?.musicWaveColor;
    const showLeaveModal = () => {
        setIsLeaveModalOpen(true);
    };

    const handleLeaveCancel = () => {
        setIsLeaveModalOpen(false);
    };

    const handleButtonClick = (buttonLink: string) => {
        if (buttonLink) {
            if (
                buttonLink.match(/^\/(faq|dining|parties|plan-your-visit|album|season-highlight)?/)
            ) {
                router.push(`/${globalStore.lang}${buttonLink}`);
                return;
            }
            if (!buttonLink.includes('hkjc.com')) {
                showLeaveModal();
                return;
            }
            window.open(buttonLink);
            return;
        }
    };

    return (
        <>
            <Flex vertical gap={24} justify={'center'} align={'center'} className={className}>
                <Flex vertical gap={8} align={'center'}>
                    <Heading
                        className={'text-base-font-color'}
                        level={3}
                        text={data.title}
                        style={{ color: '#FFFFFF' }}
                    />
                    <WaveLeftIcon iconColor={currentMusicWaveColor || musicWaveColor} />
                    <RichText
                        className={cn(style.attentionContentWrap)}
                        style={dynamicStyle}
                        html={data.content || ''}
                    />
                </Flex>
                <CustButton
                    data={{
                        setting: {
                            onClick: () => {
                                handleButtonClick(data.btn?.url || '');
                            },
                        },
                    }}
                >
                    <GeneralButtonType label={data.btn.label} />
                </CustButton>
            </Flex>
            <Dialog
                data={{
                    setting: {
                        open: isLeaveModalOpen,
                        onCancel: handleLeaveCancel,
                        width: 500,
                    },
                    className: cn('border-[#EAE219] shadow-[0_0_10px_0_#EAE219]'),
                }}
            >
                <LeaveSiteType
                    onConfirm={(e) => {
                        window.open(data.btn?.url);
                        handleLeaveCancel();
                        onButtonClick && onButtonClick();
                    }}
                    targetUrl={data.btn?.url}
                />
            </Dialog>
        </>
    );
};

export default AttentionType;
