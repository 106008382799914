'use client';
import cn from 'classnames'
import { useTranslation } from '@/i18n/use-transition.client';
import { MailOutlined } from '@ant-design/icons';
import CustButton, { GeneralButtonType } from '@componentsButton';
import { useState, useRef, useTransition } from 'react';

export type inputProp = {
    onButtonClick?: (inputValue: string) => void;
    onInputChange?: (value: string) => void;
    wrapClassName?: string
};

const emailREGEXP =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/;

const Input = ({ onButtonClick, onInputChange, wrapClassName }: inputProp) => {
    const [validated, setValidated] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const onChange = (e: any) => {
        if (inputValue.match(emailREGEXP)) {
            setValidated(true);
        }
        setInputValue(e.target.value);
        onInputChange && onInputChange(e.target.value);
    };
    const inputValidation = () => {
        if (inputValue) {
            if (inputValue.match(emailREGEXP)) {
                setValidated(true);
                onButtonClick && onButtonClick(inputValue);
                return;
            }
            setValidated(false);
        } else {
            setValidated(false);
        }
    };
    return (
        <form className={cn('basis-[341px]', wrapClassName)}>
            <div className='relative'>
                <div className='absolute inset-y-0 start-0 flex items-center pl-3.5 pointer-events-none'>
                    <MailOutlined style={{ fontSize: 16, color: 'gray' }} />
                </div>
                <input
                    ref={inputRef}
                    type={'email'}
                    id='subscription'
                    onChange={onChange}
                    value={inputValue}
                    className={`peer block w-full h-[46px] px-10 text-sm text-gray-900 border rounded-lg ${validated ? 'border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500' : 'border-red-500 text-red-900 placeholder-red-700 focus:border-red-500 bg-red-50'}`}
                    placeholder={t('footerInputPlaceholder')}
                    required
                />
                <CustButton
                    data={{
                        setting: {
                            type: 'link',
                            // href: '',
                            // htmlType: 'submit',
                            onClick: (e) => {
                                inputValidation();
                            },
                        },
                        className: '!absolute !end-2.5 !bottom-2.5 pr-3.5',
                    }}
                >
                    <GeneralButtonType
                        label={''}
                        icon={'arrowRightPrimary'}
                        // iconColor={themeColor}
                    />
                </CustButton>
            </div>
            <p className={`mt-2 text-pink-600 text-sm ${validated ? 'invisible' : 'visible'}`}>
                {t('submitEmailErrorMsg')}
            </p>
        </form>
    );
};

export default Input;
