'use client';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { Slide, Slider } from '../Slider';
import Dialog from '../Dialog';
import AttentionType from '../Dialog/AttentionType';
import { AlertPopupFragment } from '@/@types/generated/sitecore';

type Props = {
    data: AlertPopupFragment[] | undefined;
};

let timer: any = undefined;

const defaultParams = {
    startTime: '2024/04/01 11:27',
    endTime: '2024/04/01 11:29',
};

const AttentionDialog = (props: Props) => {
    const alertList = props.data;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showAlertList, setShowAlertList] = useState<AlertPopupFragment[]>([]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (alertList) {
            const showList = alertList.reduce((prev, current) => {
                if (
                    moment().isBetween(
                        current.alertStartDate?.dateValue,
                        current.alertEndDate?.dateValue,
                    ) &&
                    current.alertEnable?.boolValue &&
                    !sessionStorage.getItem(current.name)
                ) {
                    return [...prev, current];
                }
                return prev;
            }, [] as AlertPopupFragment[]);
            setShowAlertList(showList);
        }
    }, [alertList]);

    useEffect(() => {
        if (showAlertList.length > 0) {
            showModal();
        }
    }, [showAlertList]);

    return (
        <>
            <Dialog
                data={{
                    setting: {
                        open: isModalOpen,
                        onCancel: handleCancel,
                        destroyOnClose: true,
                    },
                }}
            >
                <Slider
                    settings={{
                        slidesPerView: 1,
                        pagination: {
                            clickable: true,
                        },
                    }}
                    className='w-full h-full'
                >
                    {showAlertList.map((item, index) => {
                        return (
                            <Slide key={index}>
                                {({ isActive }) => {
                                    if (isActive) {
                                        sessionStorage.setItem(item.name, 'true');
                                    }
                                    return (
                                        <AttentionType
                                            data={{
                                                title: item.alertHeader?.value || '',
                                                content: item.alertContent?.rendered || '',
                                                btn: {
                                                    label: item.alertCtaButtonText?.value || '',
                                                    url: item.alertCtaButtonLink?.value || '',
                                                },
                                            }}
                                            className={showAlertList.length > 1 ? 'pb-12' : ''}
                                            onButtonClick={() => {
                                                handleCancel();
                                            }}
                                        />
                                    );
                                }}
                            </Slide>
                        );
                    })}
                </Slider>
            </Dialog>
        </>
    );
};

export default AttentionDialog;
