'use client';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Col, Flex, Row, ThemeConfig } from 'antd';
import Text from '@componentsText';
import Heading from '@componentsHeading';
import { Icon } from '@componentsIcon';
import {
    GlobalSettingsFragment,
    InMenuItemFragment,
    MenuItemFragment,
    SocialMediaIconFragment,
} from '@/@types/generated/sitecore';
import withTheme from '@/theme';
import CustButton from '@componentsButton';
import Input from '@componentsInput';
import Dialog from '../Dialog';
import SubmitType from '../Dialog/SubmitType';
import { Locale } from '@/i18n/i18n.config';
import { withFullPath } from '@/utils/utils';
import { getYear } from 'date-fns';
import { WATrackerClickEvn, getButtonLinkWithCID } from '@/utils/wa';
import useWindowSize from '@/utils/useScreenSize';
import RichText from '../RichText';
import { useTranslation } from '@/i18n/use-transition.client';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import ImagePreview from '../ImagePreview';
import styles from './index.module.scss';

type Props = {
    footerNavList: InMenuItemFragment[];
    subFooterNavigation: MenuItemFragment[];
    globalSettingData: GlobalSettingsFragment;
    socialMediaIconData: SocialMediaIconFragment[];
    theme?: ThemeConfig;
    lang?: Locale;
};

const APP_BASE_PATH = process.env.NEXT_PUBLIC_APP_BASE_PATH
    ? `/${process.env.NEXT_PUBLIC_APP_BASE_PATH}`
    : '';

const Footer = (props: Props) => {
    const windowSize = useWindowSize();
    const {
        footerNavList,
        subFooterNavigation,
        globalSettingData,
        theme: themeProps,
        socialMediaIconData,
        lang,
    } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitStatus, setSubmitStatus] = useState<'submit' | 'success' | undefined>('submit');
    const [guid, setGuid] = useState('');
    const [captchaImg, setCaptchaImg] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [captchaValidated, setCaptchaValidated] = useState(true);
    const [localSearch, setLocalSearch] = useState('');
    const { t } = useTranslation();

    const globalStore = useGlobalStore();

    const defaultTheme = {
        components: {
            Button: {
                defaultBg: '#EAE219',
            },
        },
    };

    const theme = themeProps || defaultTheme;

    const footerNav = footerNavList.filter((v) => v.enabled?.boolValue && v.footerMenu?.boolValue);

    const showModal = () => {
        WATrackerClickEvn('RnE/HW_Click-NewsletterSubscription_Footer-SocialMedia');
        setIsModalOpen(true);
    };

    const handleLeaveCancel = () => {
        setSubmitStatus(undefined);
        setIsModalOpen(false);
    };

    const getCaptchaImg = async () => {
        const guid = uuidv4();
        // const result = await fetch(
        //     withHappyWedPrefix(`api/common/captcha?${new Date().getTime()}&guid=${guid}`),
        // ).then((res) => res.json());
        setCaptchaImg(
            `${location.origin}${APP_BASE_PATH}/api/common/captcha?${new Date().getTime()}&guid=${guid}`,
        );
        setGuid(guid);
    };

    const onConfirm = async ({
        agreementYears,
        agreement,
        verificationCode,
    }: {
        [key: string]: boolean | string;
    }) => {
        WATrackerClickEvn('RnE/HW_Click-NewsletterSubscription_Footer-SocialMedia-submit');
        if ([guid, emailValue, agreementYears, agreement, verificationCode].every(Boolean)) {
            const body = JSON.stringify({
                postData: {
                    email: btoa(encodeURIComponent(emailValue)),
                    lang: btoa(encodeURIComponent(lang || 'en-US')),
                },
                mode: 'addTwo',
                sessionID: btoa(encodeURIComponent(guid)),
                captcha: btoa(encodeURIComponent(verificationCode)),
            });

            const fetchOptions: RequestInit = {
                method: 'POST',
                body,
            };
            const result = await fetch(
                `${location.origin}${APP_BASE_PATH}/api/submit`,
                fetchOptions,
            )
                .then((res) => {
                    if (res.ok) return res.json();
                })
                .catch((error) => {
                    alert('Submit Fail. Please try again later.');
                    console.log('db-submit error', error);
                });
            if (!result) {
                alert('Submit Fail. Please try again later.');
                return;
            }
            if (result.data && result.data.includes('captcha_error')) {
                setCaptchaValidated(false);
                return;
            }
            if (result.RecordID) {
                setSubmitStatus('success');
                return;
            }
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setLocalSearch(window.location.search);
        }
    }, []);

    return withTheme(
        <>
            <Flex
                vertical
                className={
                    'bg-primary-bg-color py-10 px-5 gap-y-10 xmd:py-[78px] xmd:px-20 xmd:gap-y-[78px] w-full max-w-[1920px]'
                }
            >
                <Row gutter={[20, 20]} className={'items-center'}>
                    <Col xs={24} lg={17}>
                        <Row gutter={[64, 32]} className={''}>
                            {footerNav.map((menuCol) => (
                                <Col key={menuCol.name} className={'basis-full xmd:basis-auto'}>
                                    <CustButton
                                        data={{
                                            setting: {
                                                type: 'link',
                                                onClick: () => {
                                                    WATrackerClickEvn('RnE/HW_Click_Footer-Menu');
                                                },
                                            },
                                            buttonLink: getButtonLinkWithCID(
                                                menuCol?.destination?.value,
                                                `SPLDSP_RnE/HW/${globalStore.pageID}_Footer-Menu`,
                                                true,
                                            ),
                                        }}
                                    >
                                        <Text
                                            level='body2'
                                            text={menuCol.displayText?.value}
                                            className='text-base-font-color text-nowrap'
                                        />
                                    </CustButton>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col xs={24} lg={7}>
                        <hr className={'border-content-divide-color mb-6 block xmd:hidden'} />
                        <Flex vertical>
                            <Flex
                                gap={20}
                                className='xmd:order-2 xmd:justify-end xmd:items-center flex-col xmd:flex-row'
                                wrap={'nowrap'}
                            >
                                <Heading
                                    level={6}
                                    className='text-base-font-color xmd:text-nowrap'
                                    text={t('footerFollowUs')}
                                />
                                <Flex gap={24} className={'mb-5 xmd:mb-0 xmd:flex-nowrap'}>
                                    {socialMediaIconData.map((item, index) => (
                                        <a
                                            key={index}
                                            className={'hover:opacity-80'}
                                            onClick={() => {
                                                WATrackerClickEvn(
                                                    `RnE/HW_Click-Follow${item.name}_Footer-SocialMedia`,
                                                );
                                                window.open(item.socialLink?.value);
                                            }}
                                        >
                                            <Icon icon={item.socialIcon?.src || ''} />
                                        </a>
                                    ))}
                                </Flex>
                            </Flex>
                            <Flex className={'xmd:order-1 xmd:justify-end xmd:w-full'}>
                                <Input
                                    onButtonClick={async (emailValue: string) => {
                                        setEmailValue(emailValue);
                                        getCaptchaImg();
                                        showModal();
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </Col>
                </Row>
                <hr className={'border-content-divide-color flex items-center'} />
                <Row gutter={[97, 20]}>
                    <Col xs={24} lg={8}>
                        <ImagePreview
                            wrapClass={'cursor-pointer'}
                            setting={{
                                width: 240,
                                src: withFullPath(globalSettingData?.hwFooterLogo?.src),
                                alt: '',
                                onClick: () => {
                                    window.open(
                                        `https://www.hkjc.com/home/${lang === 'en-US' ? 'english' : 'chinese'}/index.aspx`,
                                    );
                                },
                            }}
                            canPreview={false}
                        />
                    </Col>
                    <Col xs={24} lg={16}>
                        <Flex vertical gap={8}>
                            <RichText
                                onClick={() => {
                                    WATrackerClickEvn(
                                        'RnE/HW_Click-NewsletterSubscription_Footer-RGP',
                                    );
                                }}
                                html={globalSettingData?.rgpContent?.rendered || ''}
                                statusChange={[isModalOpen]}
                            />

                            <Text
                                level={'body4'}
                                text={
                                    t('footerCopyRight')
                                        ? t('footerCopyRight').replace(
                                              '{{year}}',
                                              getYear(new Date()),
                                          )
                                        : ''
                                }
                                className={'mt-2 text-base-font-color'}
                            />

                            <Row gutter={[20, 10]} className={'divide-x [&>*:nth-child(1)]:pl-0 '}>
                                {subFooterNavigation.map((menuCol, index) => (
                                    <Col key={`${menuCol.name}${index}`}>
                                        {menuCol.enabled?.boolValue && (
                                            <CustButton
                                                data={{
                                                    setting: {
                                                        type: 'link',
                                                    },
                                                    buttonLink: `${menuCol?.destination?.value}`,
                                                }}
                                            >
                                                <Text
                                                    level='body4'
                                                    text={menuCol.displayText?.value}
                                                    className='text-base-font-color text-nowrap'
                                                />
                                            </CustButton>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </Flex>
                    </Col>
                </Row>
            </Flex>
            <Dialog
                data={{
                    setting: {
                        open: isModalOpen,
                        onCancel: handleLeaveCancel,
                        destroyOnClose: true,
                        width: 600,
                    },
                    wrapClassName: styles.submitModal,
                }}
            >
                {isModalOpen && (
                    <SubmitType
                        captchaImg={captchaImg}
                        onConfirm={onConfirm}
                        email={emailValue}
                        status={submitStatus}
                        getCaptchaImg={getCaptchaImg}
                        setCaptchaValidated={setCaptchaValidated}
                        captchaValidated={captchaValidated}
                    />
                )}
            </Dialog>
        </>,
        theme,
    );
};

export default Footer;
