'use client';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, Flex, Row, Checkbox, Image } from 'antd';
import cn from 'classnames';
import Heading from '@componentsHeading';
// import TitleIconRight from '@public/images/common/wave_icon_r.svg';
import Text from '@componentsText';
import CustButton, { GeneralButtonType } from '@componentsButton';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import RichText from '../RichText';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import { useTranslation } from '@/i18n/use-transition.client';
import useScreenSize from '@/utils/useScreenSize';
import { mobileBreakPoint } from '@/utils/utils';
import withTheme from '@/theme';

type submitProps = {
    data?: {
        title: string;
        content: string;
        confirmTitle: string;
        agreementError: string;
        agreementYears: string;
        agreement: string;
        verification: string;
        btn: {
            label: string;
        };
    };
    onConfirm?: (params: { [key: string]: boolean | string }) => void;
    captchaImg?: string;
    successData?: {
        title: string;
    };
    email?: string;
    status?: 'success' | 'submit';
    getCaptchaImg?: () => void;
    setCaptchaValidated?: Dispatch<SetStateAction<boolean>>;
    captchaValidated?: boolean;
    currentMusicWaveColor?: string;
};

const SubmitType = ({
    // data,
    captchaImg,
    onConfirm,
    // successData,
    email,
    status = 'submit',
    getCaptchaImg,
    setCaptchaValidated,
    captchaValidated = true,
    currentMusicWaveColor,
}: submitProps) => {
    const globalStore = useGlobalStore();
    const isMobile = useScreenSize().width < mobileBreakPoint;

    const musicWaveColor = globalStore?.currentTheme?.musicWaveColor;

    const { t } = useTranslation();

    const successContext = {
        // title: 'Thank you! Latest Updates will be sent to',
        title: t('submitSuccessTitle'),
    };

    const data = {
        title: t('submitFormTitle'),
        content: t('submitFormContent'),
        confirmTitle: t('submitFormConfirmTitle'),
        agreementError: t('submitFormAgreementError'),
        agreementYears: t('submitFormAgreementYears'),
        agreement: t('submitFormAgreement'),
        verification: t('submitFormVerification'),
        verificationPlaceholder: t('submitFormVerificationPlaceholder'),
        btn: {
            label: t('submitFormSubmitLabel'),
        },
    };

    const [agreement, setAgreement] = useState(false);
    const [agreementYears, setAgreementYears] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [checkboxErrorShow, setCheckboxErrorShow] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('submit');

    useEffect(() => {
        setSubmitStatus(status);
    }, [status]);

    const checkboxTheme = {
        token: {
            colorWhite: globalStore.currentTheme?.fontColor,
            colorPrimary: globalStore.currentTheme?.buttonBGColor,
        },
    };

    if (submitStatus === 'success') {
        return (
            <>
                <Flex vertical gap={24} justify={'center'} align={'center'} className='p-3'>
                    <Row>
                        <Text
                            level={'body1'}
                            text={<RichText html={successContext.title || ''} />}
                            className={'text-base-font-color text-center'}
                        />
                    </Row>
                    <Row>
                        <Text level={'body1'} text={email} className={'text-base-font-color'} />
                    </Row>
                </Flex>
            </>
        );
    }

    return (
        <div className='p-3'>
            <Flex vertical gap={24}>
                <Flex vertical gap={8} align={'center'}>
                    <Heading
                        className={'text-base-font-color'}
                        level={3}
                        text={data.title}
                        style={{ color: '#FFFFFF' }}
                    />
                    {/* <TitleIconRight /> */}
                    <WaveLeftIcon iconColor={currentMusicWaveColor || musicWaveColor} />
                    <div className={'text-base-font-color'}>
                        <RichText html={data.content || ''} />
                    </div>
                </Flex>
                <Flex vertical gap={16} justify={'center'} align={'center'}>
                    <Text
                        level={'body3'}
                        text={data.confirmTitle}
                        className={'text-base-font-color'}
                    />
                </Flex>
                {checkboxErrorShow && (
                    <Flex vertical gap={16} justify={'center'} align={'center'}>
                        <Text
                            level={'body2'}
                            text={data.agreementError}
                            className={'text-base-font-color bg-[#900] py-1 px-1'}
                        />
                    </Flex>
                )}
                <Flex vertical gap={16} align={'start'}>
                    <Row className='w-full'>
                        <Col span={1}>
                            {withTheme(
                                <Checkbox
                                    onChange={(e) => {
                                        setCheckboxErrorShow(false);
                                        setAgreementYears(e.target.checked);
                                    }}
                                />,
                                checkboxTheme,
                            )}
                        </Col>
                        <Col span={22} className={cn(isMobile ? 'pl-3' : '')}>
                            <RichText html={data.agreementYears || ''} />
                        </Col>
                    </Row>
                    <Row className='w-full'>
                        <Col span={1}>
                            {withTheme(
                                <Checkbox
                                    onChange={(e) => {
                                        setCheckboxErrorShow(false);
                                        setAgreement(e.target.checked);
                                    }}
                                />,
                                checkboxTheme,
                            )}
                        </Col>
                        <Col span={22} className={cn(isMobile ? 'pl-3' : '')}>
                            <RichText html={data.agreement || ''} />
                        </Col>
                    </Row>
                </Flex>
                <Flex vertical gap={16} justify={'start'} align={'start'}>
                    <Row>
                        <Col>
                            <Image
                                src={captchaImg}
                                alt=''
                                className='w-[180px]'
                                preview={false}
                                width={180}
                            />
                        </Col>
                        <Col>
                            <div
                                className='reload-btn cursor-pointer'
                                onClick={() => {
                                    getCaptchaImg && getCaptchaImg();
                                }}
                            >
                                <span className='ico' aria-hidden='true'>
                                    <svg
                                        color='#A0926E'
                                        viewBox='0 0 1260 1024'
                                        style={{
                                            display: 'inline-block',
                                            stroke: 'currentcolor',
                                            fill: 'currentcolor',
                                            width: '38px',
                                            height: '38px',
                                        }}
                                    >
                                        <path d='M630.154 0c-124.713 0-239.34 46.057-327.386 122.217-4.411 3.555-8.071 7.973-10.763 12.99-2.689 5.017-4.353 10.531-4.892 16.213s0.060 11.415 1.759 16.858c1.696 5.443 4.461 10.484 8.126 14.823s8.155 7.887 13.204 10.431c5.049 2.545 10.555 4.035 16.184 4.381s11.272-0.458 16.589-2.366c5.317-1.907 10.198-4.878 14.357-8.736 73.442-63.529 168.493-101.792 272.82-101.792 218.309 0 397.039 167.403 418.052 382.593h-123.98l168.041 255.063 168.041-255.063h-128.163c-21.48-261.368-238.259-467.614-501.991-467.614zM168.041 297.572l-168.041 255.063h128.164c21.48 261.369 238.257 467.616 501.99 467.616 124.713 0 239.34-46.059 327.386-122.218 4.411-3.555 8.071-7.974 10.763-12.992 2.689-5.015 4.353-10.531 4.892-16.213s-0.060-11.414-1.759-16.857c-1.696-5.443-4.461-10.484-8.126-14.824-3.665-4.338-8.155-7.887-13.204-10.432s-10.555-4.036-16.184-4.38c-5.629-0.347-11.272 0.457-16.589 2.366-5.317 1.906-10.198 4.878-14.357 8.736-73.442 63.53-168.493 101.793-272.82 101.793-218.309 0-397.038-167.403-418.051-382.595h123.98l-168.041-255.063z'></path>
                                    </svg>
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text
                                level='body2'
                                text={data.verification}
                                className='text-base-font-color'
                            />
                        </Col>
                    </Row>
                    <Row className='w-[260px]'>
                        <Col span={24}>
                            <input
                                type={'text'}
                                onChange={(e) => {
                                    setVerificationCode(
                                        e.target.value ? e.target.value.toUpperCase() : '',
                                    );
                                    setCaptchaValidated && setCaptchaValidated(true);
                                }}
                                value={verificationCode}
                                // value={inputValue}
                                className={cn(
                                    `px-3 block w-full h-[38px] text-sm text-gray-900 border rounded-lg border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 uppercase`,
                                    captchaValidated
                                        ? 'border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
                                        : 'border-red-500 text-red-900 placeholder-red-700 focus:border-red-500 bg-red-50',
                                )}
                                placeholder={data.verification}
                                maxLength={8}
                                autoComplete='false'
                                required
                            />
                            <p
                                className={`mt-2 text-pink-600 text-sm ${captchaValidated ? 'invisible' : 'visible'}`}
                            >
                                {t('submitFormVerificationError')}
                            </p>
                        </Col>
                    </Row>
                </Flex>
                <Flex gap={16} justify={'center'} align={'center'}>
                    <CustButton
                        data={{
                            setting: {
                                // href: data.btn.url,
                                onClick: () => {
                                    if (!agreementYears || !agreement) {
                                        setCheckboxErrorShow(true);
                                        return;
                                    }
                                    if (!verificationCode) {
                                        setCaptchaValidated && setCaptchaValidated(false);
                                        return;
                                    }
                                    onConfirm &&
                                        onConfirm({ agreementYears, agreement, verificationCode });
                                },
                            },
                        }}
                    >
                        <GeneralButtonType
                            label={data.btn.label}
                            icon='arrowRightBK'
                            // iconColor={themeColor}
                        />
                    </CustButton>
                </Flex>
            </Flex>
        </div>
    );
};

export default SubmitType;
