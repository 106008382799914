import * as React from 'react';

type iconProps = {
    iconColor?: string;
};

const LanguageIcon = ({ iconColor = '#fff' }: iconProps) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none'>
        <path
            fill={iconColor}
            d='M10 20a9.676 9.676 0 0 1-3.875-.788 10.144 10.144 0 0 1-3.188-2.15 10.142 10.142 0 0 1-2.15-3.187A9.676 9.676 0 0 1 0 10c0-1.383.263-2.68.787-3.888a10.183 10.183 0 0 1 2.15-3.175A10.143 10.143 0 0 1 6.126.788 9.676 9.676 0 0 1 10 0c1.383 0 2.68.263 3.887.787a10.183 10.183 0 0 1 3.175 2.15 10.184 10.184 0 0 1 2.15 3.175A9.649 9.649 0 0 1 20 10a9.676 9.676 0 0 1-.788 3.875 10.143 10.143 0 0 1-2.15 3.188 10.184 10.184 0 0 1-3.175 2.15A9.649 9.649 0 0 1 10 20Zm0-2.05c.433-.6.808-1.225 1.125-1.875.317-.65.575-1.342.775-2.075H8.1c.2.733.458 1.425.775 2.075.317.65.692 1.275 1.125 1.875Zm-2.6-.4c-.3-.55-.563-1.12-.788-1.713A14.688 14.688 0 0 1 6.05 14H3.1a8.3 8.3 0 0 0 1.812 2.175A7.195 7.195 0 0 0 7.4 17.55Zm5.2 0a7.195 7.195 0 0 0 2.488-1.375A8.299 8.299 0 0 0 16.9 14h-2.95c-.15.633-.337 1.246-.563 1.838a13.857 13.857 0 0 1-.787 1.712ZM2.25 12h3.4a13.208 13.208 0 0 1-.15-2 13.208 13.208 0 0 1 .15-2h-3.4A7.959 7.959 0 0 0 2 10a7.959 7.959 0 0 0 .25 2Zm5.4 0h4.7a13.208 13.208 0 0 0 .15-2 13.208 13.208 0 0 0-.15-2h-4.7a13.208 13.208 0 0 0-.15 2 13.208 13.208 0 0 0 .15 2Zm6.7 0h3.4a7.953 7.953 0 0 0 .25-2 7.953 7.953 0 0 0-.25-2h-3.4a13.208 13.208 0 0 1 .15 2 13.208 13.208 0 0 1-.15 2Zm-.4-6h2.95a8.298 8.298 0 0 0-1.812-2.175A7.196 7.196 0 0 0 12.6 2.45c.3.55.563 1.12.787 1.712.226.592.413 1.205.563 1.838ZM8.1 6h3.8c-.2-.733-.458-1.425-.775-2.075A12.701 12.701 0 0 0 10 2.05c-.433.6-.808 1.225-1.125 1.875C8.558 4.575 8.3 5.267 8.1 6Zm-5 0h2.95c.15-.633.338-1.246.563-1.838C6.837 3.571 7.1 3 7.4 2.45c-.933.3-1.763.758-2.488 1.375A8.298 8.298 0 0 0 3.1 6Z'
        />
    </svg>
);
export default LanguageIcon;
